@use "sass:math";

.container-util {
  &-xl-l {
    margin-left: calc((100vw - 1280px) / 2);
  }
  &-xl-r {
    margin-right: calc((100vw - 1280px) / 2);
  }
}

.container-util {
  &-lg-l {
    margin-left: calc((100vw - 1024px) / 2);
  }
  &-lg-r {
    margin-right: calc((100vw - 1024px) / 2);
  }
}
.container-util {
  &-md-l {
    margin-left: calc((100vw - 768px) / 2);
  }
  &-md-r {
    margin-right: calc((100vw - 768px) / 2);
  }
}

.container-util {
  &-sm-l {
    margin-left: calc((100vw - 640px) / 2);
  }
  &-sm-r {
    margin-right: calc((100vw - 640px) / 2);
  }
}
