// position absolute classes
.center-absolute {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center-absolute-x {
  left: 50%;
  transform: translateX(-50%);
}
.center-absolute-y {
  top: 50%;
  transform: translateY(-50%);
}

// @include media-breakpoint-up(sm) {
//   .center-absolute-sm {
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//   }

//   .center-absolute-sm-x {
//     left: 50%;
//     transform: translateX(-50%);
//   }
//   .center-absolute-sm-y {
//     top: 50%;
//     transform: translateY(-50%);
//   }
// }

// @include media-breakpoint-up(md) {
//   .center-absolute-md {
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//   }

//   .center-absolute-md-x {
//     left: 50%;
//     transform: translateX(-50%);
//   }
//   .center-absolute-md-y {
//     top: 50%;
//     transform: translateY(-50%);
//   }
// }
// @include media-breakpoint-up(lg) {
//   .center-absolute-lg {
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//   }

//   .center-absolute-lg-x {
//     left: 50%;
//     transform: translateX(-50%);
//   }
//   .center-absolute-lg-y {
//     top: 50%;
//     transform: translateY(-50%);
//   }
// }
