@import 'util-position';
@import "variables";
@import 'util-container';

html,
body {
  overflow-x: hidden;
  margin: '0';
  
}

$primary-color: #1a237e;
$secondary-color: #f2ebdb;
$tertiary-color: #e2dcd8;
$quadrary-color: #e4d7c7;

p,
li,
ol,
dl,
figure,
blockquote,
code,
strong,
em,
pre,
.readable {
  max-width: 650px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotation 10s infinite linear;
}




.horizontal-translate {
  animation: linear 10s infinite running hortranslate;
  &:hover {
    animation-play-state: paused;
  }
}
@media screen and (min-width: $media-sm) {
  .horizontal-translate {
    animation: linear 15s infinite running hortranslate;
    &:hover {
      animation-play-state: paused;
    }
  }
}
@media screen and (min-width: $media-md) {
  .horizontal-translate {
    animation: linear 25s infinite running hortranslate;
    &:hover {
      animation-play-state: paused;
    }
  }
}


@keyframes hortranslate {
  from {
    transform: translatex(-10vw);
  }
  to {
    transform: translatex(130vw);
  }
}